.private {
  // display: flex;
  padding-bottom: 0.5em;
  .private_dashboard {  
    display: grid;
    grid-template-areas: "header header header" "menu content content";
    grid-template-columns: 220px auto auto;
    // grid-template-columns: 50vw auto 50vw;
    .private_header {
      grid-area: header;
      // padding: 0 1em 0.5em 1em;
      // height: 50px;
      height: 60px;
    }
    .private_menu {
      // position: fixed;
      grid-area: menu;
      width: calc(270px - 50px);
      height: calc(100vh - 140px);
    }

    .private_content {
      grid-area: content;
      //   width: calc(100vw - 75px);
      overflow: hidden;
    }

  }
}
